import app from "./init";
import loginPhase from "./auth";
import { getAuth } from "firebase/auth";
const auth = getAuth(app);
import wait from "./popUpWait";
import modal from "./errors";
import popUp from "./popups";
import { getDatabase, get, ref, onChildRemoved, child, query, orderByValue, equalTo, onChildAdded } from "firebase/database";
import { async } from "@firebase/util";
const db = getDatabase(app);

const recyclerViewContent = document.getElementById("main-content");
let body = document.querySelector("body");

const urlValidation = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/gi;
const steamLinkValidation = /store\.steampowered\.com\/app\/[0-9]+\//g;
const epicGamesLinkValidation = /store\.epicgames\.com\/([a-zA-Z]+(-[a-zA-Z]+))+\/.*\//g;
const xboxLinkValidation = /xbox\.com\/([a-zA-Z]+(-[a-zA-Z]+)+)\/games\/store\/.*/g;
const playstationLinkValidation = /store\.playstation\.com\/([a-zA-Z]+(-[a-zA-Z]+)+)\/product\/.*/g;
const humbleBundleLinkValidation = /humblebundle\.com\/store\/.*/g;
const gogLinkValidation = /gog\.com\/.*\/game\/.*/g;
const gogLinkValidationAlt = /gog\.com\/game\/.*/g;
const dbRef = ref(db);
const freeGamesRef = ref(db, "free_games/");

let clrMode = "";
let drawMode = "_dark";
if (window.matchMedia && window.matchMedia("(prefers-color-scheme: light)").matches) {
   clrMode = "_black";
   drawMode = "";
}

const webPlat = ref(db, "platform_settings/web/");
get(webPlat).then((snapshot) => {
   if (snapshot.exists()) {
      let mode = snapshot.val().maintenance;
      if (mode == true) {
         let maintenance = document.createElement("div");
         maintenance.setAttribute("id", "maintenance");
         maintenance.innerHTML = `<a href="http://budgetgamer.app"><img src="images/icons/controller Animation.svg" alt="" class="logo"></a>
            <div><img src="./images/draws/maintenance_drawable.imageset/maintenance_drawable${drawMode}.svg" alt="" class="draw">
            <h3>The app is under maintenance</h3>
            <p>We're sorry for the inconvenience. Please try again later.</p></div>`;
         document.body.appendChild(maintenance);
      }
   }
});

let doesLinkMatchRegExp = function (url, regexp) {
   if (url.match(regexp)) {
      return true;
   } else {
      return false;
   }
};

async function getDatafromdb() {
   let platforms,
      providers = {};
   await get(child(dbRef, "platform_settings/platforms/")).then((platformData) => {
      platforms = platformData.val();
   });
   await get(child(dbRef, "platform_settings/providers/")).then((platformData) => {
      providers = platformData.val();
   });
   let gameList = [];
   let initialLoadCompleted = false;
   let count = 0;
   await onChildAdded(
      freeGamesRef,
      (gamesData) => {
         if (gamesData.exists()) {
            let game = gamesData.val();
            if (game["start_date"] <= Date.now() && game["end_date"] >= Date.now()) {
               // if (game["start_date"] <= Date.now()) {
               let provider = providers[`${game["provider_id"]}`]["name"];
               let platform = "";
               let dateFormat = new Date(game["end_date"]);
               var month = dateFormat.getMonth() + 1;
               var day = dateFormat.getDate();
               var hour = dateFormat.getHours();
               var min = dateFormat.getMinutes();
               month = (month < 10 ? "0" : "") + month;
               day = (day < 10 ? "0" : "") + day;
               hour = (hour < 10 ? "0" : "") + hour;
               min = (min < 10 ? "0" : "") + min;
               let date = day + "." + month + "." + dateFormat.getFullYear();
               let dateFull = day + "." + month + "." + dateFormat.getFullYear() + " at " + hour + ":" + min;

               let developer, releaseDate;
               if (game["developer"] == "") {
                  developer = "";
               } else {
                  developer = `<div id="developer"><h4>Developer: </h4><span>${game["developer"]}</span></div>`;
               }
               if (game["release_date"] == "") {
                  releaseDate = "";
               } else {
                  releaseDate = `<div id="release-date"><h4>Release date: </h4><span>${game["release_date"]}</span></div>`;
               }
               if (game["platform_ids"].includes(",")) {
                  let plat = game["platform_ids"].split(",");
                  for (var y = 0; y < plat.length; y++) {
                     if (plat[y].indexOf(" ") !== -1) {
                        plat[y] = plat[y].replace(/\s+/g, "");
                     }
                     if (y == plat.length - 1) {
                        try {
                           platform = platform + `<span id="platform-id" ><img src="./images/icons/platforms_logos/logo_${plat[y]}.svg" alt="">${platforms[plat[y]]["name"]}</span>`;
                        } catch (error) {}
                     } else {
                        try {
                           platform = platform + `<span id="platform-id" style="margin-right:10px"><img src="./images/icons/platforms_logos/logo_${plat[y]}.svg" alt="">${platforms[plat[y]]["name"]}</span>`;
                        } catch (error) {}
                     }
                  }
               } else {
                  platform = `<span id="platform-id"><img src="./images/icons/platforms_logos/logo_${game["platform_ids"]}.svg" alt="">${platforms[game["platform_ids"]]["name"]}</span>`;
               }

               let gameCard = document.createElement("div");
               gameCard.classList.add("card");
               gameCard.innerHTML = `<img class="image" id="${game["id"]}" src="${game["cover"]}" alt="Game image"><div id="short-info">
                            <h4>${game["name"]}</h4><div id="bottem-card-info"><div id="provider">
                            <div id="provider-img"><img src="./images/icons/provider_logos/logo_${game["provider_id"]}${[clrMode]}.svg" alt=""></div>
                            <p>${provider}</p></div><p id="end-date">Free until: ${date}</p></div></div>
                            <div class="detailed detailed-big"><button id="back"><img src="./images/icons/x.svg" alt="" id="x-close"></button>
                            <img src="${game["cover"]}" alt="Game image" class="detailed-image">
                            <div id="detailed-info"><h2>${game["name"]}</h2><div id="provider">
                            <div id="provider-img"><img src="./images/icons/provider_logos/logo_${game["provider_id"]}${[clrMode]}.svg" alt="">
                            </div><p>${provider}</p></div><div style="display: inline-flex; flex-wrap: wrap;">${platform}</div><div id="int-buttons">
                            <button id="copy" class="btn" data-clipboard-action="copy" data-clipboard-text="${game["provider_url"]}"><img src="./images/icons/link_icons/copy_link.svg" alt="">Copy link</button>
                            <a id="open" href="${game["provider_url"]}" target="_blank"><img src="./images/icons/link_icons/open_link.svg" alt="">Open link</a>
                            </div><div id="detailed-info-desc"><div id="end-date"><h4>Free until: </h4><span>${dateFull}</span></div>
                            <div id="description"><h4>Description: </h4><span>${game["description"]}</span></div>
                            ${developer}${releaseDate}</div></div>`;
               if (initialLoadCompleted) {
                  // Directly append if a new child after initial load
                  recyclerViewContent.appendChild(gameCard);
               } else {
                  gameList.push({
                     endDate: game["end_date"],
                     gameCard: gameCard,
                  });
                  count++;
               }
            }
         }
      },
      (error) => {
         console.log(error);
         let error_drawable = document.createElement("div");
         error_drawable.classList.add("error-drawable");
         error_drawable.innerHTML = `
        <img src="./images/draws/empty_drawable.imageset/loading_drawable${drawMode}.svg" alt="">
        <h4>Loading error</h4>
        <p>There was a problem loading the content for you. Please try again or contact support.</p>`;
         body.appendChild(error_drawable);
      }
   );
   initialLoadCompleted = true;
   gameList.sort((a, b) => a.endDate - b.endDate);
   for (const game of gameList) {
      recyclerViewContent.appendChild(game.gameCard);
   }
   if (count == 0) {
      let error_drawable = document.createElement("div");
      error_drawable.classList.add("error-drawable");
      error_drawable.innerHTML = `
        <img src="./images/draws/empty_drawable.imageset/empty_drawable${drawMode}.svg" alt="">
        <h4>There are no free games</h4>
        <p>Try again later, enable notifications or submit a game if you know about a free one.</p>`;
      body.appendChild(error_drawable);
   }
}

getDatafromdb().then(() => {
   document.querySelector("#loader").classList.add("fade-out");
});

onChildRemoved(freeGamesRef, (data) => {
   let game = data.val();
   setTimeout(() => {
      let card = document.getElementById(game["id"]);
      const parentNode = card.parentNode;
      parentNode.remove();
   }, 3000);
});

let detailed;

recyclerViewContent.addEventListener("click", function (event) {
   const button = event.target.closest(".card");
   if (event.target.id == "main-content") {
      return;
   }
   let openedCard;
   try {
      openedCard = document.querySelector(".opened");
      let clicked = document.querySelector(".clicked");
      if (openedCard) {
         openedCard.classList.remove("opened");
         openedCard.style.transform = "translateX(100vw)";
      }
      if (clicked) {
         clicked.classList.remove("clicked");
      }
   } catch (error) {}
   if (button) {
      button.classList.add("clicked");
      detailed = button.querySelector(".detailed");
      detailed.style.transform = "translateX(-100vw)";
      detailed.classList.add("opened");
      document.body.style.height = "100%";
      if (window.innerWidth < 960) {
         document.body.style.overflow = "hidden";
      }
      let copy = detailed.querySelector("#copy");
      copy.onclick = async () => {
         let copy_pop = document.getElementById("copy-popup");
         copy_pop.classList.add("copy-btn-clicked");
         setTimeout(() => {
            copy_pop.classList.remove("copy-btn-clicked");
         }, 1100);
      };
   }
   if (event.target.id == "back" || event.target.id == "x-close") {
      document.body.style.height = "100%";
      document.body.style.overflow = "auto";
      detailed.classList.remove("opened");
      detailed.style.transform = "translateX(100vw)";
      button.classList.remove("clicked");
   }
});

auth.onAuthStateChanged(async (user) => {
   // console.log(user)
   if (user) {
      async function openDetailed(page) {
         let openedCard;
         try {
            openedCard = document.querySelector(".opened");
            if (openedCard) {
               openedCard.classList.remove("opened");
               openedCard.style.transform = "translateX(100vw)";
            }
         } catch (error) {}
         let detailed = document.getElementById(page);
         if (detailed) {
            detailed.style.transform = "translateX(-100vw)";
            detailed.classList.add("opened");
            document.body.style.height = "100%";
            if (window.innerWidth < 960) {
               document.body.style.overflow = "hidden";
            }
         }
         let backbtn = detailed.querySelector("#back");
         backbtn.addEventListener("click", (e) => {
            document.body.style.height = "100%";
            document.body.style.overflow = "auto";
            detailed.classList.remove("opened");
            detailed.style.transform = "translateX(100vw)";
         });
      }
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      const email = user.email;
      // console.log(uid)
      let banned;
      const userCheck = ref(db, `/users/${uid}/read_only/`);
      await get(userCheck).then((snapshot) => {
         banned = snapshot.val().banned;
      });
      if (!banned) {
         modal();
         let body = document.querySelector("body");
         let btnAddgame = document.createElement("button");
         btnAddgame.setAttribute("id", "plus-button");
         btnAddgame.innerHTML = '<img src="images/icons/plus.svg" alt="">';
         body.appendChild(btnAddgame);
         let bodyAddgame = document.createElement("div");
         bodyAddgame.classList.add("detailed", "set-detailed", "detailed-big");
         bodyAddgame.setAttribute("id", "add-game-page");
         bodyAddgame.innerHTML = `
            <header><button id="back"><img src="./images/icons/arrow-left-short.svg" alt=""></button>
            <h2>Add a free game</h2></header><div id="main-edit">
            <input type="text" placeholder="Enter the game link here" id="game-link">
            <button id="submit-game-link" class="submitRegBtn">Submit</button>
            <div class="instructions"><h3>Hello!</h3>
            <p>If you find a game with 100% discount and want to share it with others,please paste the store link in the field above and we will add it
            automatically if it’s free.</p><p>We support games from:<ul><li>Steam</li><li>Humble Bundle</li><li>Xbox</li>
            <li>GOG</li><li>Playstation</li></ul></p><p>Thank you for your contribution!</p></div></div>`;
         body.appendChild(bodyAddgame);
         btnAddgame.onclick = async () => {
            openDetailed("add-game-page");
         };
         let sendgame = document.getElementById("submit-game-link");

         let input_game = document.getElementById("game-link");
         input_game.addEventListener("input", handleInputGame);

         function handleInputGame() {
            if (doesLinkMatchRegExp(input_game.value, urlValidation)) {
               if (
                  doesLinkMatchRegExp(input_game.value, steamLinkValidation) ||
                  doesLinkMatchRegExp(input_game.value, epicGamesLinkValidation) ||
                  doesLinkMatchRegExp(input_game.value, gogLinkValidation) ||
                  doesLinkMatchRegExp(input_game.value, gogLinkValidationAlt) ||
                  doesLinkMatchRegExp(input_game.value, xboxLinkValidation) ||
                  doesLinkMatchRegExp(input_game.value, playstationLinkValidation) ||
                  doesLinkMatchRegExp(input_game.value, humbleBundleLinkValidation)
               ) {
                  if (input_game.value != "") {
                     sendgame.classList.add("gradient");
                  } else {
                     try {
                        sendgame.classList.remove("gradient");
                     } catch (error) {}
                  }
               } else {
                  try {
                     sendgame.classList.remove("gradient");
                  } catch (error) {}
               }
            } else {
               try {
                  sendgame.classList.remove("gradient");
               } catch (error) {}
            }
         }

         sendgame.onclick = async () => {
            await wait(true);
            let game = document.getElementById("game-link").value;
            const accCheck = ref(db, `/game_links/accepted_links`);
            const dndCheck = ref(db, `/game_links/denied_links`);
            console.log("122");
            const accepted = query(accCheck, orderByValue(), equalTo(game));
            const denied = query(dndCheck, orderByValue(), equalTo(game));

            if (doesLinkMatchRegExp(game, urlValidation)) {
               if (
                  doesLinkMatchRegExp(game, steamLinkValidation) ||
                  doesLinkMatchRegExp(game, epicGamesLinkValidation) ||
                  doesLinkMatchRegExp(game, gogLinkValidation) ||
                  doesLinkMatchRegExp(game, gogLinkValidationAlt) ||
                  doesLinkMatchRegExp(game, xboxLinkValidation) ||
                  doesLinkMatchRegExp(game, playstationLinkValidation) ||
                  doesLinkMatchRegExp(game, humbleBundleLinkValidation)
               ) {
                  if (game != "") {
                     await get(denied).then(async (snapshot) => {
                        if (snapshot.exists()) {
                           await wait();
                           popUp("game_denied_list");
                        } else {
                           await get(accepted).then(async (snapshot) => {
                              if (snapshot.exists()) {
                                 await wait();
                                 popUp("game_accepted_list");
                              } else {
                                 var myHeaders = new Headers();
                                 myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                                 var urlencoded = new URLSearchParams();
                                 urlencoded.append("link", game);
                                 urlencoded.append("uid", uid);

                                 var requestOptions = {
                                    method: "POST",
                                    headers: myHeaders,
                                    body: urlencoded,
                                    redirect: "follow",
                                 };

                                 await fetch(`https://europe-west1-${process.env.PROJECTID}.cloudfunctions.net/gameLinkHandler`, requestOptions)
                                    .then(async (response) => {
                                       if (response.ok) {
                                          await wait();
                                          popUp("game_send");
                                       } else {
                                          await wait();
                                          popUp("game_send_failled");
                                       }
                                    })
                                    .catch(async (error) => {
                                       console.log("error", error);
                                       await wait();
                                       popUp("game_send_failled");
                                    });
                              }
                           });
                        }
                     });
                  } else {
                     await wait();
                     popUp("incorrect-link");
                  }
               } else {
                  await wait();
                  popUp("incorrect-link");
               }
            } else {
               await wait();
               popUp("incorrect-link");
            }
         };
      }
   } else {
      loginPhase();
   }
});

const observer = new MutationObserver(function (mutations_list) {
   try {
      let cards = document.querySelectorAll(".card");
      if (cards.length == 0) {
         try {
            let drawable = document.querySelector(".error-drawable");
            if (!drawable) {
               let error_drawable = document.createElement("div");
               error_drawable.classList.add("error-drawable");
               error_drawable.innerHTML = `
                    <img src="./images/draws/empty_drawable.imageset/empty_drawable${drawMode}.svg" alt="">
                    <h4>There are no free games</h4>
                    <p>Try again later, enable notifications or submit a game if you know about a free one.</p>`;
               body.appendChild(error_drawable);
            }
         } catch (error) {}
      } else if (cards.length > 0) {
         try {
            let drawable = document.querySelector(".error-drawable");
            if (drawable) {
               drawable.remove();
            }
         } catch (error) {}
      }
   } catch (error) {}
});

observer.observe(recyclerViewContent, { subtree: false, childList: true });
