const wait = async function wait(state) {
   try {
      let opened = document.querySelector(`[data-popup-opened= "true"]`);
      if (opened) {
         opened.style.opacity = "0";
         opened.style.pointerEvents = "none";
         opened.removeAttribute("data-popup-opened");
      }
   } catch (error) {}
   const popupModal = document.querySelector(`[data-popup-modal="please_wait_dots"]`);
   let bodyBlackout = document.querySelector(`.body-blackout`);
   if (state) {
      // console.log("start")
      popupModal.style.opacity = "1";
      popupModal.style.pointerEvents = "none";
      bodyBlackout.style.display = "block";
      popupModal.setAttribute("data-popup-opened", "true");
   } else {
      await popupModal.style.removeProperty("opacity");
      await bodyBlackout.style.removeProperty("display");
      popupModal.removeAttribute("data-popup-opened");
      // console.log("stop")
   }
};

export default wait;
