const modal = async function errors() {
   const body = document.querySelector("body");
   let bodyBlackout = document.createElement("div");
   bodyBlackout.classList.add("body-blackout");
   bodyBlackout.innerHTML = `
    <div class="popup-modal" data-popup-modal="please_wait_dots"><h3>Sending</h3>
    <p>Please wait...</p><button class="error" id="wait_button">
    <svg class="spinner_wait" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
  <circle class="path_wait" fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="27"></circle>
</svg></button></div>
    <div class="popup-modal" data-popup-modal="name"><h3>Error</h3>
    <p>Please fill in name.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="email"><h3>Error</h3>
    <p>The email is in invalid format.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="password"><h3>Error</h3>
    <p>Please make sure your password is at least 8 characters, contains a special character and a number.</p>
    <button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="same-passwords"><h3>Error</h3>
    <p>The old and new passwords are the same.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="repeat-password"><h3>Error</h3>
    <p>Passwords do not match.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="email-already-exists"><h3>Error</h3>
    <p>The provided email is already in use by an existing user.</p>
    <button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="internal-error"><h3>Error</h3>
    <p>The Authentication server encountered an unexpected error while trying to process the request. Please try again.</p>
    <button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="user-created"><h3>Success</h3>
    <p>Account created</p><button class="error" onClick="window.location.reload();">Ok</button></div>
    <div class="popup-modal" data-popup-modal="user-not-found"><h3>Error</h3>
    <p>We couldn't find any account corresponding to this e-mail. Please make sure you typed your e-mail correctly.</p>
    <button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="signOut"><h3>Sign out</h3>
    <p>Are your sure you want to sign out now?</p>
    <div style="display: flex; justify-content: space-between;"><button class="no">No</button>
    <button class="error">Yes</button></div></div>
    <div class="popup-modal" data-popup-modal="alerts"><h3>Alerts</h3>
    <p>Do you want to enable notifications?</p>
    <div style="display: flex; justify-content: space-between;"><button class="no">No</button>
    <button class="error">Yes</button></div></div>
    <div class="popup-modal" data-popup-modal="deleteAcc"><h3>Delete account</h3>
    <p>Are your sure you want to delete your account?<br>To be able to perform this action we need to reauthenticate you.</p>
    <div style="display: flex; justify-content: space-between;"><button class="no">No</button>
    <button class="error">Yes</button></div></div>
    <div class="popup-modal" data-popup-modal="notifications-arent-supported"><h3>Error</h3>
    <p>Notifications aren't supported on this browser.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="notifications-already-denied"><h3>Error</h3>
    <p>The permission for notifications was denied at an earlier date for this website, please enable or reset it and try again.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="notifications-error"><h3>Error</h3>
    <p>We encountered an error, please try again later.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="notifications-enabled"><h3>Success</h3>
    <p>The notifications are enabled. We will keep you updated with the latest free to keep games.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="name-changed"><h3>Success</h3>
    <p>Account name was changed.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="email-changed"><h3>Success</h3>
    <p>Account e-mail was changed.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="pass-changed"><h3>Success</h3>
    <p>Account password was changed.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="acc-deleted"><h3>Success</h3>
    <p>Account was deleted</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="wrong-password"><h3>Error</h3>
    <p>Wrong password</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="recover-email-sent"><h3>Success</h3>
    <p>Password reset email sent!</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="incorrect-link"><h3>Error</h3>
    <p>The link provided is either in an incorrect format or is not in our supported providers's list.</p>
    <button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="article_denied_list"><h3>Error</h3>
    <p>This link is either not valid or it is in our banned list.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="article_accepted_list"><h3>Error</h3>
    <p>This link is already in our database.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="article_send"><h3>Success</h3>
    <p>The link you submitted has been send. We will verify it and add it shortly.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="article_send_failled"><h3>Error</h3>
    <p>The was a problem sending the article. Please try again later.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="game_denied_list"><h3>Error</h3>
    <p>The game is either not free or not supported.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="game_accepted_list"><h3>Error</h3>
    <p>The game already exists in our database.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="game_send"><h3>Success</h3>
    <p>We received your submission and we will add it shortly if it's free.</p><button class="error">Ok</button></div>
    <div class="popup-modal" data-popup-modal="game_send_failed"><h3>Error</h3>
    <p>There was a problem sending the link. Please try again later.</p><button class="error">Ok</button></div>
    <div class="popup-modal popup-modal-share" data-popup-modal="share_app"><h3>Share app</h3>
    <div class="clipboard"><span>https://budgetgamer.app</span>
    <button class="btn" data-clipboard-action="copy" data-clipboard-text="https://budgetgamer.app">
    <img src="./images/icons/settings_icons/link-45deg.svg" alt=""></button></div>
    <div><a class=”twitter-share-button” target="_blank"
    href="https://twitter.com/intent/tweet?text=Hey!%20I%20am%20using%20Budget%20Gamer%20for%20keeping%20track%20of%20the%20latest%20free%20games.%0ADownload%20it%20from%20here:%0AApp%20Store:%0Ahttps://apps.apple.com/app/id6443675890%0AGoogle%20Play:%0Ahttps://play.google.com/store/apps/details?id=com.tomorrowit.budgetgamer&hl=en">
    <img src="./images/icons/settings_icons/twitter.svg" alt=""></a>
    <a href="https://www.facebook.com/sharer/sharer.php?u=budgetgamer.app&title=Budget%20Gamer" target=”_blank”><img src="./images/icons/settings_icons/facebook.svg" alt=""></a>
    <a href="https://www.reddit.com/submit?text=Hey!%20I%20am%20using%20Budget%20Gamer%20for%20keeping%20track%20of%20the%20latest%20free%20games.%0ADownload%20it%20from%20here%3A%0AApp%20Store%3A%0Ahttps%3A%2F%2Fapps.apple.com%2Fapp%2Fid6443675890%0AGoogle%20Play%3A%0Ahttps%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tomorrowit.budgetgamer&hl=en&title=Budget%20Gamer"
    target=”_blank”><img src="./images/icons/settings_icons/reddit.svg" alt=""></a>
    <a href="https://api.whatsapp.com/send?text=Hey!%20I%20am%20using%20Budget%20Gamer%20for%20keeping%20track%20of%20the%20latest%20free%20games.%0ADownload%20it%20from%20here%3A%0AApp%20Store%3A%0Ahttps%3A%2F%2Fapps.apple.com%2Fapp%2Fid6443675890%0AGoogle%20Play%3A%0Ahttps%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tomorrowit.budgetgamer&hl=en&title=Budget%20Gamer"
    data-action="share/whatsapp/share" target=”_blank”><img src="./images/icons/settings_icons/whatsapp_logo.svg" alt=""></a></div></div>`;

   body.appendChild(bodyBlackout);
};
export default modal;
