const popUp = async function (popupTrigger) {
   try {
      let opened = document.querySelector(`[data-popup-opened= "true"]`);
      if (opened) {
         opened.style.opacity = "0";
         opened.style.pointerEvents = "none";
         opened.removeAttribute("data-popup-opened");
      }
   } catch (error) {}

   const popupModal = document.querySelector(`[data-popup-modal="${popupTrigger}"]`);
   let bodyBlackout = document.querySelector(`.body-blackout`);
   popupModal.style.opacity = "1";
   popupModal.style.pointerEvents = "auto";
   bodyBlackout.style.display = "block";
   popupModal.setAttribute("data-popup-opened", "true");

   popupModal.querySelector(".error").addEventListener("click", async () => {
      await popupModal.style.removeProperty("opacity");
      popupModal.style.pointerEvents = "none";
      await bodyBlackout.style.removeProperty("display");
      popupModal.removeAttribute("data-popup-opened");
   });
   setTimeout(() => {
      async function removePop() {
         await popupModal.style.removeProperty("opacity");
         popupModal.style.pointerEvents = "none";
         await bodyBlackout.style.removeProperty("display");
         popupModal.removeAttribute("data-popup-opened");
         setTimeout(() => {
            bodyBlackout.removeEventListener("click", removePop);
         }, 200);
      }
      bodyBlackout.addEventListener("click", removePop);
   }, 500);
};
export default popUp;
