import app from "./init";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile, sendPasswordResetEmail, updateEmail } from "firebase/auth";
import { getDatabase, get, ref } from "firebase/database";
import modal from "./errors";
import popUp from "./popups";
import wait from "./popUpWait";

const auth = getAuth(app);
const db = getDatabase(app);
const providerG = new GoogleAuthProvider();
providerG.addScope("profile");
providerG.addScope("email");

const loginPhase = async function authBanner() {
   modal();
   var path = window.location.pathname;
   var page = path.split("/").pop();

   let body = document.querySelector("body");
   let main = document.querySelector(".main");
   let mainLoginDiv = document.createElement("div");
   mainLoginDiv.setAttribute("id", "mainLoginDiv");
   mainLoginDiv.innerHTML = `<img src="./images/icons/settings_icons/person-circle-fill.svg" alt="">
    <p>You are in guest mode. Sign up to get alerts and submit free games or ongoing giveaways.</p>
    <button id="newloginbtn"><p>Sign up / Log in</p></button>`;

   let noLoginBanner = document.createElement("div");
   noLoginBanner.setAttribute("id", "no-login");
   noLoginBanner.innerHTML = `<p id="banner">You are in guest mode. If you want to get alerts and submit free games or ongoing giveaways click here to
        register or sign in.</p><div id="close"><img src="./images/icons/x.svg" alt="" id="x-close"></div>`;

   let closedBanner = sessionStorage.getItem("banner");
   // console.log(closedBanner)
   if (closedBanner == "closed") {
      noLoginBanner.style.display = "none";
   }
   body.insertBefore(noLoginBanner, main);
   let closeBanner = document.getElementById("close");
   closeBanner.onclick = async () => {
      noLoginBanner.style.display = "none";
      sessionStorage.setItem("banner", "closed");
   };

   let welcomeAuth = document.createElement("div");
   welcomeAuth.setAttribute("id", "welcome");
   welcomeAuth.classList.add("detailed", "set-detailed", "welcome-pop");
   welcomeAuth.style.zIndex = "6";
   welcomeAuth.innerHTML = `<button id="back"><img src="./images/icons/x.svg" alt=""></button>
    <div id="main-welcome">
        <img src="images/icons/controller Animation.svg" alt="">
        <p style="font-size: 30px; margin: 15px 0px 30px 0px">Welcome!</p>
        <button id="login">Login</button>
        <div id="login-page" class="detailed set-detailed">
            <header><button id="back"><img src="./images/icons/arrow-left-short.svg" alt=""></button>
                <h2>Login</h2>
            </header>
            <div id="main-login">
                <p>E-mail</p>
                <input type="email" placeholder="example@domain.com" id="login-email">
                <p>Password</p>
                <input type="password" placeholder="Minimum 8 characters" id="login-pass">
                <button id="forgot">Forgot your password?</button>
            </div>
            <button id="login-fb" class="submitRegBtn">Login</button>
            
        </div>
        <div id="recover-acc-page" class="detailed set-detailed">
            <header><button id="back"><img src="./images/icons/arrow-left-short.svg" alt=""></button>
                <h2>Recover account</h2>
            </header>
            <div id="main-edit">
                <input type="email" placeholder="example@domain.com" id="recover-acc-input">
            </div>
            <button id="recover-acc" class="submitRegBtn">Submit</button>
        </div>
        <button id="register" style="margin-bottom: 30px;">Register</button>
        <div id="terms-register" class="detailed set-detailed">
            <header><button id="back"><img src="./images/icons/arrow-left-short.svg" alt=""></button>
                <h2>Terms and Conditions</h2>
            </header>
            <div>
                <p>Updated at 2022-10-01</p>

                <h1>General Terms</h1>
                <p>Never lose a game again. Game stores often have time limited giveaways or bundles for free
                    games on PC, Xbox and
                    Playstation, so you have to be fast to not miss them. "Budget Gamer" checks your favorite
                    stores like Steam,
                    Epic Games, Xbox, GoG, Humble Bundle and Playstation, so you don't have to. The most common
                    are Steam Free Weeks
                    and Free Weekends and the Free Epic Games of the Week from the Epic Games Store, which are
                    free-to-keep! </p>
                <p>
                    It's our job to find the best free game offers for you and gather them in one place. Our
                    smart and efficient
                    algorithm is constantly searching all the stores and gaming platforms to save your time,
                    energy and money!
                </p>
                <p>
                    Budget Gamer will not be responsible for any outcome that may occur during
                    the course of usage of our resources. We reserve the rights to change and
                    revise the resources usage policy in any moment.
                </p>
                <p>
                    The Budget Gamer app stores and processes personal data that you have provided to us, in
                    order to provide our
                    Service. It’s your responsibility to keep your phone and access to the app secure. We
                    therefore recommend that
                    you do not jailbreak or root your phone, which is the process of removing software
                    restrictions and limitations
                    imposed by the official operating system of your device. It could make your phone vulnerable
                    to
                    malware/viruses/malicious programs, compromise your phone’s security features and it could
                    mean that the
                    Budget Gamer app won’t work properly or at all.
                </p>

                <h1>License</h1>
                <p>
                    Budget Gamer grants you a revocable, non-exclusive, non-transferable,
                    limited license to download, install and use the app strictly in
                    accordance with the terms of this Agreement.
                </p>
                <p>
                    These Terms & Conditions are a contract between you and Budget Gamer
                    (referred to in these Terms & Conditions as "Budget Gamer", "us", "we" or
                    "our"), the provider of the Budget Gamer website and the services
                    accessible from the Budget Gamer website (which are collectively referred
                    to in these Terms & Conditions as the "Budget Gamer Service").
                </p>
                <p>
                    You are agreeing to be bound by these Terms & Conditions. If you do not
                    agree to these Terms & Conditions, please do not use the Budget Gamer
                    Service. In these Terms & Conditions, "you" refers both to you as an
                    individual and to the entity you represent. If you violate any of these
                    Terms & Conditions, we reserve the right to cancel your account or block
                    access to your account without notice.
                </p>

                <h1>Definitions and key terms</h1>
                <p>
                    To help explain things as clearly as possible in this Terms & Conditions,
                    every time any of these terms are referenced, are strictly defined as:
                </p>
                <ul>
                    <li>
                        Cookie: small amount of data generated by a website and saved by your
                        web browser. It is used to identify your browser, provide analytics,
                        remember information about you such as your language preference or login
                        information.
                    </li>
                    <li>
                        Company: when this policy mentions “Company,” “we,” “us,” or “our,” it
                        refers to 2Morrow IT Solutions, that is
                        responsible for your information under this Terms & Conditions.
                    </li>
                    <li>
                        Country: where Budget Gamer or the owners/founders of Budget Gamer are
                        based, in this case is RO
                    </li>
                    <li>
                        Device: any internet connected device such as a phone, tablet, computer
                        or any other device that can be used to visit Budget Gamer and use the
                        services.
                    </li>
                    <li>
                        Service: refers to the service provided by Budget Gamer as described in
                        the relative terms (if available) and on this platform.
                    </li>
                    <li>
                        Third-party service: refers to advertisers, contest sponsors,
                        promotional and marketing partners, and others who provide our content
                        or whose products or services we think may interest you.
                    </li>
                    <li>
                        You: a person or entity that is registered with Budget Gamer to use the
                        Services.
                    </li>
                </ul>

                <h1>Restrictions</h1>
                <p>You agree not to, and you will not permit others to:</p>
                <ul>
                    <li>
                        License, sell, rent, lease, assign, distribute, transmit, host,
                        outsource, disclose or otherwise commercially exploit the app or make
                        the platform available to any third party.
                    </li>
                    <li>
                        Modify, make derivative works of, disassemble, decrypt, reverse compile
                        or reverse engineer any part of the app.
                    </li>
                    <li>
                        Remove, alter or obscure any proprietary notice (including any notice of
                        copyright or trademark) of Budget Gamer or its affiliates, partners,
                        suppliers or the licensors of the app.
                    </li>
                </ul>

                <h1>Your Suggestions</h1>
                <p>
                    Any feedback, comments, ideas, improvements or suggestions (collectively,
                    "Suggestions") provided by you to Budget Gamer with respect to the app
                    shall remain the sole and exclusive property of Budget Gamer.
                </p>
                <p>
                    Budget Gamer shall be free to use, copy, modify, publish, or redistribute
                    the Suggestions for any purpose and in any way without any credit or any
                    compensation to you.
                </p>

                <h1>Your Consent</h1>
                <p>
                    We've updated our Terms & Conditions to provide you with complete
                    transparency into what is being set when you download our app and how it's
                    being used. By using our app and registering an account, you hereby
                    consent to our Terms & Conditions.
                </p>

                <h1>Links to Other Websites</h1>
                <p>
                    This Terms & Conditions applies only to the Services. The Services may
                    contain links to other websites not operated or controlled by Budget Gamer.
                    We are not responsible for the content, accuracy or opinions expressed in
                    such websites, and such websites are not investigated, monitored or
                    checked for accuracy or completeness by us. Please remember that when you
                    use a link to go from the Services to another website, our Terms &
                    Conditions are no longer in effect. Your browsing and interaction on any
                    other website, including those that have a link on our platform, is
                    subject to that website’s own rules and policies. Such third parties may
                    use their own cookies or other methods to collect information about you.
                </p>

                <h1>Changes To Our Terms & Conditions</h1>
                <p>
                    You acknowledge and agree that Budget Gamer
                    may stop (permanently or temporarily) providing the Service (or any
                    features within the Service) to you or to users generally at
                    Budget Gamer’s sole discretion, without prior notice to you. You may
                    stop using the Service at any time. You do not need to specifically inform
                    Budget Gamer when you stop using the Service. You acknowledge and agree that if
                    Budget Gamer disables access to your account, you may be prevented from accessing the
                    Service, your account details or any files or other materials which is
                    contained in your account.
                </p>
                </p>

                <p>
                    If we decide to change our Terms & Conditions, we will post those changes
                    on this page, and/or update the Terms & Conditions modification date
                    above.
                </p>

                <h1>Modifications to Our app</h1>
                <p>
                    Budget Gamer reserves the right to modify, suspend or discontinue,
                    temporarily or permanently, the app or any service to which it connects,
                    with or without notice and without liability to you.
                </p>

                <h1>Updates to Our app</h1>
                <p>
                    Budget Gamer may from time to time provide enhancements or improvements to
                    the features/ functionality of the app, which may include patches, bug
                    fixes, updates, upgrades and other modifications ("Updates").
                </p>
                <p>
                    Updates may modify or delete certain features and/or functionalities of
                    the app. You agree that Budget Gamer has no obligation to (i) provide any
                    Updates, or (ii) continue to provide or enable any particular features
                    and/or functionalities of the app to you.
                </p>
                <p>
                    You further agree that all Updates will be (i) deemed to constitute an
                    integral part of the app, and (ii) subject to the terms and conditions of
                    this Agreement.
                </p>

                <h1>Third-Party Services</h1>
                <p>
                    We include or make available third-party content (including
                    data, information, applications and other products services) or provide
                    links to third-party websites or services ("Third- Party Services").
                </p>
                <p>
                    You acknowledge and agree that Budget Gamer shall not be responsible for
                    any Third-Party Services, including their accuracy, completeness,
                    timeliness, validity, copyright compliance, legality, decency, quality or
                    any other aspect thereof. Budget Gamer does not assume and shall not have
                    any liability or responsibility to you or any other person or entity for
                    any Third-Party Services.
                </p>
                <p>
                    Third-Party Services and links thereto are provided solely as a
                    convenience to you and you access and use them entirely at your own risk
                    and subject to such third parties' terms and conditions.
                </p>
                <p>
                    Link to Terms and Conditions of third party service providers used by the app
                <ul>
                    <li><a href="https://policies.google.com/terms"> Google Play Services</a></li>
                    <li><a href="https://firebase.google.com/terms/analytics">Google Analytics for Firebase</a>
                    </li>
                    <li><a href="https://firebase.google.com/terms/crashlytics">Firebase Crashlytics</a></li>
                </ul>
                </p>

                <p>
                    With respect to the Company responsibility for your use of the app, when you’re using the
                    app, it’s important to
                    bear in mind that although we endeavour to ensure that it is updated and correct at all
                    times, we do rely on
                    third parties to provide information to us so that we can make it available to you. The
                    Company accepts no
                    liability for any loss, direct or indirect, you experience as a result of relying wholly on
                    this functionality
                    of the app.
                </p>

                <h1>Term and Termination</h1>
                <p>
                    This Agreement shall remain in effect until terminated by you or
                    Budget Gamer.
                </p>
                <p>
                    Budget Gamer may, in its sole discretion, at any time and for any or no
                    reason, suspend or terminate this Agreement with or without prior notice.
                </p>
                <p>
                    This Agreement will terminate immediately, without prior notice from
                    Budget Gamer, in the event that you fail to comply with any provision of
                    this Agreement. You may also terminate this Agreement by deleting the app
                    and all copies thereof from your device.
                </p>
                <p>
                    Upon termination of this Agreement, you shall cease all use of the app and
                    delete all copies of the app from your device.
                </p>
                <p>
                    Termination of this Agreement will not limit any of Budget Gamer's rights
                    or remedies at law or in equity in case of breach by you (during the term
                    of this Agreement) of any of your obligations under the present Agreement.
                </p>

                <h1>Copyright Infringement Notice</h1>
                <p>
                    If you are a copyright owner or such owner’s agent and believe any
                    material on our app constitutes an infringement on your copyright, please
                    contact us setting forth the following information: (a) a physical or
                    electronic signature of the copyright owner or a person authorized to act
                    on his behalf; (b) identification of the material that is claimed to be
                    infringing; (c) your contact information, including your address,
                    telephone number, and an email; (d) a statement by you that you have a
                    good faith belief that use of the material is not authorized by the
                    copyright owners; and (e) the a statement that the information in the
                    notification is accurate, and, under penalty of perjury you are authorized
                    to act on behalf of the owner.
                </p>

                <h1>Indemnification</h1>
                <p>
                    You agree to indemnify and hold Budget Gamer and its parents, subsidiaries,
                    affiliates, officers, employees, agents, partners and licensors (if any)
                    harmless from any claim or demand, including reasonable attorneys' fees,
                    due to or arising out of your: (a) use of the app; (b) violation of this
                    Agreement or any law or regulation; or (c) violation of any right of a
                    third party.
                </p>

                <h1>No Warranties</h1>
                <p>
                    The app is provided to you "AS IS" and "AS AVAILABLE" and with all faults
                    and defects without warranty of any kind. To the maximum extent permitted
                    under applicable law, Budget Gamer, on its own behalf and on behalf of its
                    affiliates and its and their respective licensors and service providers,
                    expressly disclaims all warranties, whether express, implied, statutory or
                    otherwise, with respect to the app, including all implied warranties of
                    merchantability, fitness for a particular purpose, title and
                    non-infringement, and warranties that may arise out of course of dealing,
                    course of performance, usage or trade practice. Without limitation to the
                    foregoing, Budget Gamer provides no warranty or undertaking, and makes no
                    representation of any kind that the app will meet your requirements,
                    achieve any intended results, be compatible or work with any other
                    software, , systems or services, operate without interruption, meet any
                    performance or reliability standards or be error free or that any errors
                    or defects can or will be corrected.
                </p>
                <p>
                    Without limiting the foregoing, neither Budget Gamer nor any Budget Gamer's
                    provider makes any representation or warranty of any kind, express or
                    implied: (i) as to the operation or availability of the app, or the
                    information, content, and materials or products included thereon; (ii)
                    that the app will be uninterrupted or error-free; (iii) as to the
                    accuracy, reliability, or currency of any information or content provided
                    through the app; or (iv) that the app, its servers, the content, or
                    e-mails sent from or on behalf of Budget Gamer are free of viruses,
                    scripts, trojan horses, worms, malware, timebombs or other harmful
                    components.
                </p>
                <p>
                    Some jurisdictions do not allow the exclusion of or limitations on implied
                    warranties or the limitations on the applicable statutory rights of a
                    consumer, so some or all of the above exclusions and limitations may not
                    apply to you.
                </p>

                <h1>Limitation of Liability</h1>
                <p>
                    Notwithstanding any damages that you might incur, the entire liability of
                    Budget Gamer and any of its suppliers under any provision of this Agreement
                    and your exclusive remedy for all of the foregoing shall be limited to the
                    amount actually paid by you for the app.
                </p>
                <p>
                    To the maximum extent permitted by applicable law, in no event shall
                    Budget Gamer or its suppliers be liable for any special, incidental,
                    indirect, or consequential damages whatsoever (including, but not limited
                    to, damages for loss of profits, for loss of data or other information,
                    for business interruption, for personal injury, for loss of privacy
                    arising out of or in any way related to the use of or inability to use the
                    app, third-party software and/or third-party hardware used with the app,
                    or otherwise in connection with any provision of this Agreement), even if
                    Budget Gamer or any supplier has been advised of the possibility of such
                    damages and even if the remedy fails of its essential purpose.
                </p>
                <p>
                    Some states/jurisdictions do not allow the exclusion or limitation of
                    incidental or consequential damages, so the above limitation or exclusion
                    may not apply to you.
                </p>

                <p>
                    You should be aware that there are certain things that the company will not take
                    responsibility for. Certain
                    functions of the app will require the app to have an active internet connection. The
                    connection can be Wi-Fi, or
                    provided by your mobile network provider, but the company cannot take responsibility for the
                    app not working at
                    full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data
                    allowance left.
                </p>

                <p>If you’re using the app outside of an area with Wi-Fi, you should remember that your terms of
                    the agreement with
                    your mobile network provider will still apply. As a result, you may be charged by your
                    mobile provider for the
                    cost of data for the duration of the connection while accessing the app, or other third
                    party charges. In using
                    the app, you’re accepting responsibility for any such charges, including roaming data
                    charges if you use the app
                    outside of your home territory (i.e. region or country) without turning off data roaming. If
                    you are not the
                    bill payer for the device on which you’re using the app, please be aware that we assume that
                    you have received
                    permission from the bill payer for using the app.</p>

                <p>Along the same lines, the company cannot always take responsibility for the way you use the
                    app i.e. You need to
                    make sure that your device stays charged – if it runs out of battery and you can’t turn it
                    on to avail the
                    Service, the company cannot accept responsibility.</p>

                <h1>Severability</h1>
                <p>
                    If any provision of this Agreement is held to be unenforceable or invalid,
                    such provision will be changed and interpreted to accomplish the
                    objectives of such provision to the greatest extent possible under
                    applicable law and the remaining provisions will continue in full force
                    and effect.
                </p>
                <p>
                    This Agreement, together with the Privacy Policy and any other legal
                    notices published by Budget Gamer on the Services, shall constitute the
                    entire agreement between you and Budget Gamer concerning the Services. If
                    any provision of this Agreement is deemed invalid by a court of competent
                    jurisdiction, the invalidity of such provision shall not affect the
                    validity of the remaining provisions of this Agreement, which shall remain
                    in full force and effect. No waiver of any term of this Agreement shall be
                    deemed a further or continuing waiver of such term or any other term, and
                    Budget Gamer's failure to assert any right or provision under this
                    Agreement shall not constitute a waiver of such right or provision. YOU
                    AND Budget Gamer AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED
                    TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
                    ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
                </p>

                <h1>Waiver</h1>
                <p>
                    Except as provided herein, the failure to exercise a right or to require
                    performance of an obligation under this Agreement shall not effect a
                    party's ability to exercise such right or require such performance at any
                    time thereafter nor shall be the waiver of a breach constitute waiver of
                    any subsequent breach.
                </p>
                <p>
                    o failure to exercise, and no delay in exercising, on the part of either
                    party, any right or any power under this Agreement shall operate as a
                    waiver of that right or power. Nor shall any single or partial exercise of
                    any right or power under this Agreement preclude further exercise of that
                    or any other right granted herein. In the event of a conflict between this
                    Agreement and any applicable purchase or other terms, the terms of this
                    Agreement shall govern.
                </p>

                <h1>Amendments to this Agreement</h1>
                <p>
                    Budget Gamer reserves the right, at its sole discretion, to modify or
                    replace this Agreement at any time. If a revision is material we will
                    provide at least 30 days' notice prior to any new terms taking effect.
                    What constitutes a material change will be determined at our sole
                    discretion.
                </p>
                <p>
                    By continuing to access or use our app after any revisions become
                    effective, you agree to be bound by the revised terms. If you do not agree
                    to the new terms, you are no longer authorized to use Budget Gamer.
                </p>

                <h1>Entire Agreement</h1>
                <p>
                    The Agreement constitutes the entire agreement between you and Budget Gamer
                    regarding your use of the app and supersedes all prior and contemporaneous
                    written or oral agreements between you and Budget Gamer.
                </p>
                <p>
                    You may be subject to additional terms and conditions that apply when you
                    use or purchase other Budget Gamer's services, which Budget Gamer will
                    provide to you at the time of such use or purchase.
                </p>

                <h1>Updates to Our Terms</h1>
                <p>
                    We may change our Service and policies, and we may need to make changes to
                    these Terms so that they accurately reflect our Service and policies.
                    Unless otherwise required by law, we will notify you (for example, through
                    our Service) before we make changes to these Terms and give you an
                    opportunity to review them before they go into effect. Then, if you
                    continue to use the Service, you will be bound by the updated Terms. If
                    you do not want to agree to these or any updated Terms, you can delete
                    your account.
                </p>

                <h1>Intellectual Property</h1>
                <p>
                    The app and its entire contents, features and functionality (including but
                    not limited to all information, software, text, displays, images, video
                    and audio, and the design, selection and arrangement thereof), are owned
                    by Budget Gamer, its licensors or other providers of such material and are
                    protected by RO and international copyright, trademark, patent, trade
                    secret and other intellectual property or proprietary rights laws. The
                    material may not be copied, modified, reproduced, downloaded or
                    distributed in any way, in whole or in part, without the express prior
                    written permission of Budget Gamer, unless and except as is expressly
                    provided in these Terms & Conditions. Any unauthorized use of the material
                    is prohibited.
                </p>

                <h1>Agreement to Arbitrate</h1>
                <p>
                    This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A DISPUTE
                    RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE
                    ENFORCEMENT OR VALIDITY OF YOUR OR Budget Gamer's INTELLECTUAL PROPERTY
                    RIGHTS. The term “dispute” means any dispute, action, or other controversy
                    between you and Budget Gamer concerning the Services or this agreement,
                    whether in contract, warranty, tort, statute, regulation, ordinance, or
                    any other legal or equitable basis. “Dispute” will be given the broadest
                    possible meaning allowable under law.
                </p>

                <h1>Notice of Dispute</h1>
                <p>
                    In the event of a dispute, you or Budget Gamer must give the other a Notice
                    of Dispute, which is a written statement that sets forth the name,
                    address, and contact information of the party giving it, the facts giving
                    rise to the dispute, and the relief requested. You must send any Notice of
                    Dispute via email to: <a href="mailto:contact@budgetgamer.app">contact@budgetgamer.app</a><br />
                    Budget Gamer will send any Notice of Dispute to you by mail to your address
                    if we have it, or otherwise to your email address. You and Budget Gamer
                    will attempt to resolve any dispute through informal negotiation within
                    sixty (60) days from the date the Notice of Dispute is sent. After sixty
                    (60) days, you or Budget Gamer may commence arbitration.
                </p>

                <h1>Binding Arbitration</h1>
                <p>
                    If you and Budget Gamer don’t resolve any dispute by informal negotiation,
                    any other effort to resolve the dispute will be conducted exclusively by
                    binding arbitration as described in this section. You are giving up the
                    right to litigate (or participate in as a party or class member) all
                    disputes in court before a judge or jury. The dispute shall be settled by
                    binding arbitration in accordance with the commercial arbitration rules of
                    the American Arbitration Association. Either party may seek any interim or
                    preliminary injunctive relief from any court of competent jurisdiction, as
                    necessary to protect the party’s rights or property pending the completion
                    of arbitration. Any and all legal, accounting, and other costs, fees, and
                    expenses incurred by the prevailing party shall be borne by the
                    non-prevailing party.
                </p>

                <h1>Submissions and Privacy</h1>
                <p>
                    In the event that you submit or post any ideas, creative suggestions,
                    designs, photographs, information, advertisements, data or proposals,
                    including ideas for new or improved products, services, features,
                    technologies or promotions, you expressly agree that such submissions will
                    automatically be treated as non-confidential and non-proprietary and will
                    become the sole property of Budget Gamer without any compensation or credit
                    to you whatsoever. Budget Gamer and its affiliates shall have no
                    obligations with respect to such submissions or posts and may use the
                    ideas contained in such submissions or posts for any purposes in any
                    medium in perpetuity, including, but not limited to, developing,
                    manufacturing, and marketing products and services using such ideas.
                </p>

                <h1>Promotions</h1>
                <p>
                    Budget Gamer may, from time to time, include contests, promotions,
                    sweepstakes, or other activities (“Promotions”) that require you to submit
                    material or information concerning yourself. Please note that all
                    Promotions may be governed by separate rules that may contain certain
                    eligibility requirements, such as restrictions as to age and geographic
                    location. You are responsible to read all Promotions rules to determine
                    whether or not you are eligible to participate. If you enter any
                    Promotion, you agree to abide by and to comply with all Promotions Rules.
                </p>

                <h1>Typographical Errors</h1>
                <p>
                    In the event a product and/or service is listed with an incorrect information due to
                    typographical error, we
                    shall have the
                    right to refuse or cancel any information listed at the incorrect price.
                </p>

                <h1>Miscellaneous</h1>
                <p>
                    If for any reason a court of competent jurisdiction finds any provision or
                    portion of these Terms & Conditions to be unenforceable, the remainder of
                    these Terms & Conditions will continue in full force and effect. Any
                    waiver of any provision of these Terms & Conditions will be effective only
                    if in writing and signed by an authorized representative of Budget Gamer.
                    Budget Gamer will be entitled to injunctive or other equitable relief
                    (without the obligations of posting any bond or surety) in the event of
                    any breach or anticipatory breach by you. Budget Gamer operates and
                    controls the Budget Gamer Service from its offices in RO. The Service is
                    not intended for distribution to or use by any person or entity in any
                    jurisdiction or country where such distribution or use would be contrary
                    to law or regulation. Accordingly, those persons who choose to access the
                    Budget Gamer Service from other locations do so on their own initiative and
                    are solely responsible for compliance with local laws, if and to the
                    extent local laws are applicable. These Terms & Conditions (which include
                    and incorporate the Budget Gamer Privacy Policy) contains the entire
                    understanding, and supersedes all prior understandings, between you and
                    Budget Gamer concerning its subject matter, and cannot be changed or
                    modified by you. The section headings used in this Agreement are for
                    convenience only and will not be given any legal import.
                </p>

                <h1>Disclaimer</h1>
                <p>
                    Budget Gamer is not responsible for any content, code or any other
                    imprecision.
                </p>
                <p>Budget Gamer does not provide warranties or guarantees.</p>
                <p>
                    In no event shall Budget Gamer be liable for any special, direct, indirect,
                    consequential, or incidental damages or any damages whatsoever, whether in
                    an action of contract, negligence or other tort, arising out of or in
                    connection with the use of the Service or the contents of the Service. The
                    Company reserves the right to make additions, deletions, or modifications
                    to the contents on the Service at any time without prior notice.
                </p>
                <p>
                    The Budget Gamer Service and its contents are provided "as is" and "as
                    available" without any warranty or representations of any kind, whether
                    express or implied. Budget Gamer is a distributor and not a publisher of
                    the content supplied by third parties; as such, Budget Gamer exercises no
                    editorial control over such content and makes no warranty or
                    representation as to the accuracy, reliability or currency of any
                    information, content, service or merchandise provided through or
                    accessible via the Budget Gamer Service. Without limiting the foregoing,
                    Budget Gamer specifically disclaims all warranties and representations in
                    any content transmitted on or in connection with the Budget Gamer Service
                    or on sites that may appear as links on the Budget Gamer Service, or in the
                    products provided as a part of, or otherwise in connection with, the
                    Budget Gamer Service, including without limitation any warranties of
                    merchantability, fitness for a particular purpose or non-infringement of
                    third party rights. No oral advice or written information given by
                    Budget Gamer or any of its affiliates, employees, officers, directors,
                    agents, or the like will create a warranty. Price and availability
                    information is subject to change without notice. Without limiting the
                    foregoing, Budget Gamer does not warrant that the Budget Gamer Service will
                    be uninterrupted, uncorrupted, timely, or error-free.
                </p>

                <h2>Contact Us</h2>
                <p>Don't hesitate to contact us if you have any questions.</p>
                <ul>
                    <li>
                        Via Email:
                        <a href="mailto:contact@budgetgamer.app">contact@budgetgamer.app</a>
                    </li>
                </ul>
            </div>
            <button id="accept-terms" class="loginRegBTn">Accept</button>
        </div>
        <div id="gdpr-register" class="detailed set-detailed">
            <header><button id="back"><img src="./images/icons/arrow-left-short.svg" alt=""></button>
                <h2>GDPR</h2>
            </header>
            <div>
                <p>Updated at 2022-10-01</p>
                <br>
                <p> Budget Gamer (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy
                    explains how
                    your
                    personal information is collected, used, and disclosed by Budget Gamer.</p>
                <p>This Privacy Policy applies to its associated subdomains (collectively, our “Service”) alongside
                    our application, Budget Gamer. By accessing or using our Service, you signify that you have
                    read, understood,
                    and
                    agree to our collection, storage, use, and disclosure of your personal information as described
                    in this Privacy
                    Policy and our Terms of Service.</p>

                <h2>Definitions and key terms</h2>
                <p>To help explain things as clearly as possible in this Privacy Policy, every time any of these
                    terms are
                    referenced,
                    are strictly defined as:
                <ul>
                    <li>Cookie: small amount of data generated by a website and saved by your web browser. It is
                        used to
                        identify your
                        browser, provide analytics, remember information about you such as your language preference
                        or login
                        information.
                    </li>
                    <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to 2Morrow IT
                        Solutions
                        that is
                        responsible for your information under this Privacy Policy.</li>
                    <li>Country: where Budget Gamer or the owners/founders of Budget Gamer are based, in this case
                        is Romania
                    </li>
                    <li>Customer: refers to the company, organization or person that signs up to use the Budget
                        Gamer Service to
                        manage
                        the relationships with your consumers or service users.</li>
                    <li>Device: any internet connected device such as a phone, tablet, computer or any other device
                        that can be
                        used to
                        visit Budget Gamer and use the services.</li>
                    <li>IP address: Every device connected to the Internet is assigned a number known as an Internet
                        protocol
                        (IP)
                        address. These numbers are usually assigned in geographic blocks. An IP address can often be
                        used to
                        identify the
                        location from which a device is connecting to the Internet.</li>
                    <li>Personnel: refers to those individuals who are employed by Budget Gamer or are under
                        contract to perform
                        a
                        service on behalf of one of the parties.</li>
                    <li>Personal Data: any information that directly, indirectly, or in connection with other
                        information —
                        including a
                        personal identification number — allows for the identification or identifiability of a
                        natural person.
                    </li>
                    <li>Service: refers to the service provided by Budget Gamer as described in the relative terms
                        (if
                        available) and on
                        this platform.</li>
                    <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing
                        partners, and
                        others who
                        provide our content or whose products or services we think may interest you.</li>
                    <li>You: a person or entity that is registered with Budget Gamer to use the Services.</li>
                </ul>
                </p>
                <h2>What Information Do We Collect?</h2>
                <p>We collect information from you when you visit our app, register or subscribe to our
                    services.</p>

                <h2>How Do We Use The Information We Collect?</h2>
                <p>Any of the information we collect from you may be used in one of the following ways:
                <ul>
                    <li>To personalize your experience (your information helps us to better respond to your
                        individual needs)
                    </li>
                    <li>To improve our app (we continually strive to improve our app offerings based on the
                        information and
                        feedback we
                        receive from you)</li>
                    <li>To improve customer service (your information helps us to more effectively respond to your
                        customer
                        service
                        requests and support needs)</li>
                    <li>To administer a contest, promotion, survey or other site feature</li>
                </ul>
                </p>

                <h2>When does Budget Gamer use end user information from third parties?</h2>
                <p>Budget Gamer will collect End User Data necessary to provide the Budget Gamer services to our
                    customers.</p>
                <p>End users may voluntarily provide us with information they have made available on social media
                    websites. If you
                    provide us with any such information, we may collect publicly available information from the
                    social media
                    websites
                    you have indicated. You can control how much of your information social media websites make
                    public by visiting
                    these
                    websites and changing your privacy settings.</p>

                <h2>When does Budget Gamer use customer information from third parties?</h2>
                <p>We receive some information from the third parties when you contact us. For example, when you
                    submit your email
                    address to us to show interest in becoming a Budget Gamer customer, we receive information from
                    a third party
                    that
                    provides automated fraud detection services to Budget Gamer. We also occasionally collect
                    information that is
                    made
                    publicly available on social media websites. You can control how much of your information social
                    media websites
                    make
                    public by visiting these websites and changing your privacy settings.</p>

                <h2>Do we share the information we collect with third parties?</h2>
                <p>We may engage trusted third party service providers to perform functions and provide services to
                    us, such as
                    hosting and maintaining our servers and the app, database storage and management, e-mail
                    management, storage
                    marketing, credit card processing, customer service and fulfilling orders for products and
                    services you may
                    purchase
                    through the app. We will likely share your personal information, and possibly some non-personal
                    information,
                    with
                    these third parties to enable them to perform these services for us and for you.</p>
                <p>We may share portions of our log file data, including IP addresses, for analytics purposes with
                    third parties
                    such
                    as web analytics partners, application developers, and ad networks. If your IP address is
                    shared, it may be used
                    to
                    estimate general location and other technographics such as connection speed, whether you have
                    visited the app in
                    a
                    shared location, and type of the device used to visit the app. They may aggregate information
                    about our
                    advertising
                    and what you see on the app and then provide auditing, research and reporting for us and our
                    advertisers. We may
                    also disclose personal and non-personal information about you to government or law enforcement
                    officials or
                    private
                    parties as we, in our sole discretion, believe necessary or appropriate in order to respond to
                    claims, legal
                    process
                    (including subpoenas), to protect our rights and interests or those of a third party, the safety
                    of the public
                    or
                    any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to
                    otherwise comply
                    with
                    applicable court orders, laws, rules and regulations.</p>

                <h2>Where and when is information collected from customers and end users?</h2>
                <p>Budget Gamer will collect personal information that you submit to us. We may also receive
                    personal information
                    about
                    you from third parties as described above.</p>

                <h2>How Do We Use Your Email Address?</h2>
                <p>By submitting your email address on this app, you agree to receive emails from us. You can cancel
                    your
                    participation in any of these email lists at any time by clicking on the opt-out link or other
                    unsubscribe
                    option
                    that is included in the respective email. We only send emails to people who have authorized us
                    to contact them,
                    either directly, or through a third party. We do not send unsolicited commercial emails, because
                    we hate spam as
                    much as you do. By submitting your email address, you also agree to allow us to use your email
                    address for
                    customer
                    audience targeting on sites like Facebook, where we display custom advertising to specific
                    people who have
                    opted-in
                    to receive communications from us. Email addresses submitted only through the order processing
                    page will be used
                    for
                    the sole purpose of sending you information and updates pertaining to your order. If, however,
                    you have provided
                    the
                    same email to us through another method, we may use it for any of the purposes stated in this
                    Policy. Note: If
                    at
                    any time you would like to unsubscribe from receiving future emails, we include detailed
                    unsubscribe
                    instructions at
                    the bottom of each email.</p>

                <h2>How Long Do We Keep Your Information?</h2>
                <p>We keep your information only so long as we need it to provide Budget Gamer to you and fulfill
                    the purposes
                    described in this policy. This is also the case for anyone that we share your information with
                    and who carries
                    out
                    services on our behalf. When we no longer need to use your information and there is no need for
                    us to keep it to
                    comply with our legal or regulatory obligations, we’ll either remove it from our systems or
                    depersonalize it so
                    that
                    we can't identify you.</p>

                <h2>How Do We Protect Your Information?</h2>
                <p>We implement a variety of security measures to maintain the safety of your personal information
                    when you place an
                    order or enter, submit, or access your personal information. We offer the use of a secure
                    server. All supplied
                    sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then
                    encrypted into our
                    Payment gateway providers database only to be accessible by those authorized with special access
                    rights to such
                    systems, and are required to keep the information confidential. After a transaction, your
                    private information
                    (credit cards, social security numbers, financials, etc.) is never kept on file. We cannot,
                    however, ensure or
                    warrant the absolute security of any information you transmit to Budget Gamer or guarantee that
                    your information
                    on
                    the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our
                    physical, technical,
                    or
                    managerial safeguards.</p>

                <h2>Could my information be transferred to other countries?</h2>
                <p>Budget Gamer is incorporated in Romania. Information collected via our website, through direct
                    interactions with
                    you, or from use of our help services may be transferred from time to time to our offices or
                    personnel, or to
                    third
                    parties, located throughout the world, and may be viewed and hosted anywhere in the world,
                    including countries
                    that
                    may not have laws of general applicability regulating the use and transfer of such data. To the
                    fullest extent
                    allowed by applicable law, by using any of the above, you voluntarily consent to the
                    trans-border transfer and
                    hosting of such information.</p>

                <h2>Is the information collected through the Budget Gamer Service secure?</h2>
                <p>We take precautions to protect the security of your information. We have physical, electronic,
                    and managerial
                    procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly
                    use your
                    information. However, neither people nor security systems are foolproof, including encryption
                    systems. In
                    addition,
                    people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while
                    we use
                    reasonable
                    efforts to protect your personal information, we cannot guarantee its absolute security. If
                    applicable law
                    imposes
                    any non-disclaimable duty to protect your personal information, you agree that intentional
                    misconduct will be
                    the
                    standards used to measure our compliance with that duty.</p>

                <h2>Can I update or correct my information?</h2>
                <p>The rights you have to request updates or corrections to the information Budget Gamer collects
                    depend on your
                    relationship with Budget Gamer. Personnel may update or correct their information as detailed in
                    our internal
                    company
                    employment policies.</p>
                <p>Customers have the right to request the restriction of certain uses and disclosures of personally
                    identifiable
                    information as follows. You can contact us in order to (1) update or correct your personally
                    identifiable
                    information, (2) change your preferences with respect to communications and other information
                    you receive from
                    us,
                    or (3) delete the personally identifiable information maintained about you on our systems
                    (subject to the
                    following
                    paragraph), by cancelling your account. Such updates, corrections, changes and deletions will
                    have no effect on
                    other information that we maintain, or information that we have provided to third parties in
                    accordance with
                    this
                    Privacy Policy prior to such update, correction, change or deletion. To protect your privacy and
                    security, we
                    may
                    take reasonable steps (such as requesting a unique password) to verify your identity before
                    granting you profile
                    access or making corrections. You are responsible for maintaining the secrecy of your unique
                    password and
                    account
                    information at all times.</p>
                <p>You should be aware that it is not technologically possible to remove each and every record of
                    the information
                    you
                    have provided to us from our system. The need to back up our systems to protect information from
                    inadvertent
                    loss
                    means that a copy of your information may exist in a non-erasable form that will be difficult or
                    impossible for
                    us
                    to locate. Promptly after receiving your request, all personal information stored in databases
                    we actively use,
                    and
                    other readily searchable media will be updated, corrected, changed or deleted, as appropriate,
                    as soon as and to
                    the
                    extent reasonably and technically practicable.</p>
                <p>If you are an end user and wish to update, delete, or receive any information we have about you,
                    you may do so by
                    contacting the organization of which you are a customer.</p>
                <h2>Personnel</h2>
                <p>If you are a Budget Gamer worker or applicant, we collect information you voluntarily provide to
                    us. We use the
                    information collected for Human Resources purposes in order to administer benefits to workers
                    and screen
                    applicants.
                </p>
                <p>You may contact us in order to (1) update or correct your information, (2) change your
                    preferences with respect
                    to
                    communications and other information you receive from us, or (3) receive a record of the
                    information we have
                    relating to you. Such updates, corrections, changes and deletions will have no effect on other
                    information that
                    we
                    maintain, or information that we have provided to third parties in accordance with this Privacy
                    Policy prior to
                    such
                    update, correction, change or deletion.</p>

                <h2>Governing Law</h2>
                <p>This Privacy Policy is governed by the laws of Romania without regard to its conflict of laws
                    provision. You
                    consent to the exclusive jurisdiction of the courts in connection with any action or dispute
                    arising between the
                    parties under or in connection with this Privacy Policy except for those individuals who may
                    have rights to make
                    claims under Privacy Shield, or the Swiss-US framework.</p>
                <p>The laws of Romania, excluding its conflicts of law rules, shall govern this Agreement and your
                    use of the app.
                    Your use of the app may also be subject to other local, state, national, or international laws.
                </p>
                <p>By using Budget Gamer or contacting us directly, you signify your acceptance of this Privacy
                    Policy. If you do
                    not
                    agree to this Privacy Policy, you should not engage with our website, or use our services.
                    Continued use of the
                    website, direct engagement with us, or following the posting of changes to this Privacy Policy
                    that do not
                    significantly affect the use or disclosure of your personal information will mean that you
                    accept those changes.
                </p>

                <h2>Your Consent</h2>
                <p>We've updated our Privacy Policy to provide you with complete transparency into what is being set
                    when you visit
                    our site and how it's being used. By using our app, registering an account, or making a
                    purchase, you hereby
                    consent
                    to our Privacy Policy and agree to its terms.
                </p>

                <h2>Links to Other Websites</h2>
                <p>This Privacy Policy applies only to the Services. The Services may contain links to other
                    websites not operated
                    or
                    controlled by Budget Gamer. We are not responsible for the content, accuracy or opinions
                    expressed in such
                    websites,
                    and such websites are not investigated, monitored or checked for accuracy or completeness by us.
                    Please remember
                    that when you use a link to go from the Services to another website, our Privacy Policy is no
                    longer in effect.
                    Your
                    browsing and interaction on any other website, including those that have a link on our platform,
                    is subject to
                    that
                    website’s own rules and policies. Such third parties may use their own cookies or other methods
                    to collect
                    information about you.</p>

                <h2>Remarketing Services</h2>
                <p>We use remarketing services. What Is Remarketing? In digital marketing, remarketing (or
                    retargeting) is the
                    practice of serving ads across the internet to people who have already visited your website. It
                    allows your
                    company
                    to seem like they're “following” people around the internet by serving ads on the websites and
                    platforms they
                    use
                    most.</p>
                <h2>Kids' Privacy</h2>
                <p>We collect information from kids under the age of 13 just to better our services. If You are a
                    parent or guardian
                    and You are aware that Your child has provided Us with Personal Data without your permission,
                    please contact Us.
                    If
                    We become aware that We have collected Personal Data from anyone under the age of 13 without
                    verification of
                    parental consent, We take steps to remove that information from Our servers.</p>
                <h2>Changes To Our Privacy Policy</h2>
                <p>We may change our Service and policies, and we may need to make changes to this Privacy Policy so
                    that they
                    accurately reflect our Service and policies. Unless otherwise required by law, we will notify
                    you (for example,
                    through our Service) before we make changes to this Privacy Policy and give you an opportunity
                    to review them
                    before
                    they go into effect. Then, if you continue to use the Service, you will be bound by the updated
                    Privacy Policy.
                    If
                    you do not want to agree to this or any updated Privacy Policy, you can delete your account.</p>

                <h2>Third-Party Services</h2>
                <p>We may display, include or make available third-party content (including data, information,
                    applications and
                    other
                    products services) or provide links to third-party websites or services ("Third- Party
                    Services").</p>
                <p>You acknowledge and agree that Budget Gamer shall not be responsible for any Third-Party
                    Services, including
                    their
                    accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality
                    or any other
                    aspect
                    thereof. Budget Gamer does not assume and shall not have any liability or responsibility to you
                    or any other
                    person
                    or entity for any Third-Party Services.</p>
                <p>Third-Party Services and links thereto are provided solely as a convenience to you and you access
                    and use them
                    entirely at your own risk and subject to such third parties' terms and conditions.</p>
                <h2>Tracking Technologies</h2>
                <ul>
                    <li>Local Storage
                        <br>
                        <p>Local Storage sometimes known as DOM storage, provides web apps with methods and
                            protocols for storing
                            client-side data. Web storage supports persistent data storage, similar to cookies but
                            with a greatly
                            enhanced
                            capacity and no information stored in the HTTP request header.</p>
                    </li>
                    <li>Firebase Crashlytics
                        <br>
                        <p>A crash reporting solution for developers of mobile applications ("Application(s)"),
                            including analyzing
                            crash
                            reports to provide information about how and under what circumstances such applications
                            crashed</p>
                    </li>
                    <li>Google Analytics
                        <br>
                        <p>The collection of data about App Users, including via identifiers for mobile devices
                            (including Android
                            Advertising ID and Advertising Identifier for iOS), cookies and similar technologies.
                        </p>
                    </li><br>
                </ul>
                <h2>Information about General Data Protection Regulation (GDPR)</h2>
                <p>We may be collecting and using information from you if you are from the European Economic Area
                    (EEA), and in
                    this
                    section of our Privacy Policy we are going to explain exactly how and why is this data
                    collected, and how we
                    maintain this data under protection from being replicated or used in the wrong way.</p>

                <h2>What is GDPR?</h2>
                <p>GDPR is an EU-wide privacy and data protection law that regulates how EU residents' data is
                    protected by
                    companies
                    and enhances the control the EU residents have, over their personal data.</p>
                <p>The GDPR is relevant to any globally operating company and not just the EU-based businesses and
                    EU residents.
                    Our
                    customers’ data is important irrespective of where they are located, which is why we have
                    implemented GDPR
                    controls
                    as our baseline standard for all our operations worldwide.</p>

                <h2>What is personal data?</h2>
                <p>Any data that relates to an identifiable or identified individual. GDPR covers a broad spectrum
                    of
                    information that
                    could be used on its own, or in combination with other pieces of information, to identify a
                    person. Personal
                    data
                    extends beyond a person’s name or email address. Some examples include financial information,
                    political
                    opinions,
                    genetic data, biometric data, IP addresses, physical address, sexual orientation, and ethnicity.
                </p>
                <p>The Data Protection Principles include requirements such as:</p>
                <ul>
                    <li>Personal data collected must be processed in a fair, legal, and transparent way and should
                        only be used
                        in a way
                        that a person would reasonably expect.</li>
                    <li>Personal data should only be collected to fulfil a specific purpose and it should only be
                        used for that
                        purpose.
                        Organizations must specify why they need the personal data when they collect it.</li>
                    <li>Personal data should be held no longer than necessary to fulfil its purpose.</li>
                    <li>People covered by the GDPR have the right to access their own personal data. They can also
                        request a
                        copy of
                        their data, and that their data be updated, deleted, restricted, or moved to another
                        organization.</li>
                </ul>

                <h2>Why is GDPR important?</h2>
                <p>GDPR adds some new requirements regarding how companies should protect individuals' personal data
                    that they
                    collect
                    and process. It also raises the stakes for compliance by increasing enforcement and imposing
                    greater fines
                    for
                    breach. Beyond these facts it's simply the right thing to do. At Budget Gamer we strongly
                    believe that your
                    data
                    privacy is very important and we already have solid security and privacy practices in place that
                    go beyond
                    the
                    requirements of this new regulation.</p>

                <h2>Individual Data Subject's Rights - Data Access, Portability and Deletion</h2>
                <p>We are committed to helping our customers meet the data subject rights requirements of GDPR.
                    Budget Gamer
                    processes
                    or stores all personal data in fully vetted, DPA compliant vendors. We do store all conversation
                    and
                    personal data
                    for up to 6 years unless your account is deleted. In which case, we dispose of all data in
                    accordance with
                    our Terms
                    of Service and Privacy Policy, but we will not hold it longer than 60 days.</p>
                <p>We are aware that if you are working with EU customers, you need to be able to provide them with
                    the ability
                    to
                    access, update, retrieve and remove personal data. We got you! We've been set up as self service
                    from the
                    start and
                    have always given you access to your data and your customers data. Our customer support team is
                    here for you
                    to
                    answer any questions you might have about working with the API.</p>
                <h2>California Residents</h2>
                <p>The California Consumer Privacy Act (CCPA) requires us to disclose categories of Personal
                    Information we
                    collect
                    and how we use it, the categories of sources from whom we collect Personal Information, and the
                    third
                    parties with
                    whom we share it, which we have explained above.</p>
                <p>We are also required to communicate information about rights California residents have under
                    California law.
                    You
                    may exercise the following rights:</p>
                <ul>
                    <li>Right to Know and Access. You may submit a verifiable request for information regarding the:
                        (1)
                        categories of
                        Personal Information we collect, use, or share; (2) purposes for which categories of
                        Personal
                        Information are
                        collected or used by us; (3) categories of sources from which we collect Personal
                        Information; and (4)
                        specific
                        pieces of Personal Information we have collected about you.</li>
                    <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy
                        rights.</li>
                    <li>Right to Delete. You may submit a verifiable request to close your account and we will
                        delete Personal
                        Information about you that we have collected.</li>
                    <li>Request that a business that sells a consumer's personal data, not sell the consumer's
                        personal data.
                    </li>
                </ul>
                <p>If you make a request, we have one month to respond to you. If you would like to exercise any of
                    these
                    rights,
                    please contact us.</p>
                <p>We do not sell the Personal Information of our users.</p>
                <p>For more information about these rights, please contact us.</p>
                <h2>California Online Privacy Protection Act (CalOPPA)</h2>
                <p>CalOPPA requires us to disclose categories of Personal Information we collect and how we use it,
                    the
                    categories of
                    sources from whom we collect Personal Information, and the third parties with whom we share it,
                    which we
                    have
                    explained above.</p>
                <p>CalOPPA users have the following rights:</p>
                <ul>
                    <li>Right to Know and Access. You may submit a verifiable request for information regarding the:
                        (1)
                        categories of
                        Personal Information we collect, use, or share; (2) purposes for which categories of
                        Personal
                        Information are
                        collected or used by us; (3) categories of sources from which we collect Personal
                        Information; and (4)
                        specific
                        pieces of Personal Information we have collected about you.</li>
                    <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy
                        rights.</li>
                    <li>Right to Delete. You may submit a verifiable request to close your account and we will
                        delete Personal
                        Information about you that we have collected.</li>
                    <li>Right to request that a business that sells a consumer's personal data, not sell the
                        consumer's personal
                        data.
                    </li>
                </ul>
                <p>If you make a request, we have one month to respond to you. If you would like to exercise any of
                    these
                    rights,
                    please contact us.</p>
                <p>We do not sell the Personal Information of our users.</p>
                <p>For more information about these rights, please contact us.</p>
                <h2>Contact Us</h2>
                <p>Don't hesitate to contact us if you have any questions.</p>
                <ul>
                    <li>
                        Via Email:
                        <a href="mailto:contact@budgetgamer.app">contact@budgetgamer.app</a>
                    </li>
                </ul>
            </div>
            <button id="accept-gdpr" class="loginRegBTn">Accept</button>
        </div>
        <div id="register-page" class="detailed set-detailed">
            <header><button id="back"><img src="./images/icons/arrow-left-short.svg" alt=""></button>
                <h2>Register</h2>
            </header>
            <div id="main-register">
                <p>Name</p>
                <input type="text" placeholder="Joe Doe" id="register-name">
                <p>E-mail</p>
                <input type="email" placeholder="example@domain.com" id="register-email">
                <p>Password</p>
                <input type="password" placeholder="Minimum 8 characters" id="register-pass">
                <p>Repeat password</p>
                <input type="password" placeholder="Repeat your password" id="register-pass-confirm">
            </div>

            <button id="register-fb" class="submitRegBtn">Register</button>
        </div>
        <p>or sign in with</p>
        <div class="alt-login">
            <button id="google-login"><img
                    src="./images/icons/settings_icons/logo_google.svg" alt=""></button>
                    <!-- <button id="apple-login"><img src="./images/icons/settings_icons/logo_apple.svg" alt=""></button> -->
        </div>
    </div>`;
   body.appendChild(welcomeAuth);
   if (window.innerWidth >= 600) {
      let blackoutDiv = document.createElement("div");
      blackoutDiv.classList.add("blackout");
      body.appendChild(blackoutDiv);
   }
   if (page.includes("settings")) {
      let general = document.getElementById("general");
      noLoginBanner.style.display = "none";
      main.insertBefore(mainLoginDiv, general);
      let newloginbtn = document.getElementById("newloginbtn");
      newloginbtn.onclick = async () => {
         let detailed = document.getElementById("welcome");
         if (detailed) {
            detailed.style.transform = "translateX(-100vw)";
            document.body.style.height = "100%";
            document.body.style.overflow = "hidden";
            main.style.overflow = "hidden";
         }
         let blackout;
         try {
            blackout = document.querySelector(`.blackout`);
            blackout.style.display = "block";
         } catch (error) {}
         let backbtn = detailed.querySelector("#back");
         backbtn.addEventListener("click", (e) => {
            document.body.style.height = "100%";
            document.body.style.overflow = "auto";
            main.style.overflow = "auto";
            detailed.style.transform = "translateX(100vw)";
            try {
               blackout.style.display = "none";
            } catch (error) {}
         });
      };
   }
   const google = document.getElementById("google-login");
   let banner = document.getElementById("banner");
   let login = document.getElementById("login");
   let register = document.getElementById("register");
   let acceptTerms = document.getElementById("accept-terms");
   let acceptGdpr = document.getElementById("accept-gdpr");
   let registerBtn = document.getElementById("register-fb");
   let loginBtn = document.getElementById("login-fb");
   let recoverAcc = document.getElementById("forgot");
   let recoverAccBtn = document.getElementById("recover-acc");

   const bodyBlackout = document.querySelector(".body-blackout");

   async function openDetailed(page) {
      let detailed = document.getElementById(page);
      if (detailed) {
         detailed.style.transform = "translateX(-100vw)";
         document.body.style.overflow = "hidden";
      }
      let backbtn = detailed.querySelector("#back");
      backbtn.addEventListener("click", (e) => {
         document.body.style.overflow = "auto";
         detailed.style.transform = "translateX(100vw)";
      });
   }
   banner.onclick = async () => {
      let detailed = document.getElementById("welcome");
      if (detailed) {
         detailed.style.transform = "translateX(-100vw)";
         document.body.style.overflow = "hidden";
      }
      let blackout;
      try {
         blackout = document.querySelector(`.blackout`);
         blackout.style.display = "block";
      } catch (error) {}

      let backbtn = detailed.querySelector("#back");
      backbtn.addEventListener("click", (e) => {
         document.body.style.overflow = "auto";
         detailed.style.transform = "translateX(100vw)";
         try {
            blackout.style.display = "none";
         } catch (error) {
            console.log("aici");
         }
      });
   };
   let regOrGog;
   login.onclick = async () => {
      openDetailed("login-page");
   };
   register.onclick = async () => {
      openDetailed("terms-register");
      regOrGog = false;
   };
   google.onclick = async () => {
      openDetailed("terms-register");
      regOrGog = true;
   };
   acceptTerms.onclick = async () => {
      openDetailed("gdpr-register");
   };
   acceptGdpr.onclick = async () => {
      if (!regOrGog) {
         openDetailed("register-page");
      } else {
         wait(true);
         googleLogin();
      }
   };
   recoverAcc.onclick = async () => {
      openDetailed("recover-acc-page");
   };
   const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

   async function googleLogin() {
      signInWithPopup(auth, providerG)
         .then(async (result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // console.log(user, result);
            // console.log(auth.currentUser);

            // const email = result._tokenResponse.email;
            // console.log(result, email, auth.currentUser, auth.currentUser.user.uid);
            // await updateEmail(auth.currentUser, email)
            //    .then(() => {})
            //    .catch((error) => {
            //       console.log(error);
            //    });
            // await updateProfile(auth.currentUser, { email: email });
            // const usrRef = ref(db, `users/${auth.currentUser.user.uid}`);

            window.location.reload();
            // IdP data available using getAdditionalUserInfo(result)
            // ...
         })
         .catch(async (error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            // const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            if (errorCode == "auth/internal-error") {
               await wait();
               popUp("internal-error");
            } else {
               await wait();
               popUp("internal-error");
            }
         });
   }

   let reg_name = document.getElementById("register-name");
   let reg_email = document.getElementById("register-email");
   let reg_password = document.getElementById("register-pass");
   let reg_confpass = document.getElementById("register-pass-confirm");

   reg_name.addEventListener("input", handleInputReg);
   reg_email.addEventListener("input", handleInputReg);
   reg_password.addEventListener("input", handleInputReg);
   reg_confpass.addEventListener("input", handleInputReg);

   function handleInputReg() {
      if (
         reg_name.value &&
         reg_name.value != "" &&
         reg_email.value.match(emailRegex) &&
         reg_email.value != "" &&
         reg_password.value.match(/[A-Z]/) &&
         reg_password.value.match(/[a-z]/) &&
         reg_password.value.match(/[0-9]/) &&
         reg_password.value.match(/[^A-Za-z0-9]/) &&
         reg_password.value.length > 7 &&
         reg_password.value == reg_confpass.value
      ) {
         registerBtn.classList.add("gradient");
      } else {
         try {
            registerBtn.classList.remove("gradient");
         } catch (error) {}
      }
   }

   registerBtn.addEventListener("click", async (e) => {
      wait(true);
      const name = document.getElementById("register-name").value;
      const email = document.getElementById("register-email").value;
      const password = document.getElementById("register-pass").value;
      const confpass = document.getElementById("register-pass-confirm").value;

      if (name == "") {
         await wait();
         popUp("name");
      } else if (!email.match(emailRegex) || email == "") {
         await wait();
         popUp("email");
      } else if (!password.match(/[A-Z]/) || !password.match(/[a-z]/) || !password.match(/[0-9]/) || !password.match(/[^A-Za-z0-9]/) || password.length < 8) {
         await wait();
         popUp("password");
      } else if (password != confpass) {
         await wait();
         popUp("repeat-password");
      } else {
         const userCredential = await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
               const user = userCredential.user;
               console.log(user);
               updateProfile(user, { displayName: name })
                  .then(
                     async () => {
                        await wait();
                        try {
                           let opened = document.querySelector(`[data-popup-opened= "true"]`);
                           if (opened) {
                              opened.style.opacity = "0";
                              opened.style.pointerEvents = "none";
                              opened.removeAttribute("data-popup-opened");
                           }
                        } catch (error) {}
                        const popupModal = document.querySelector(`[data-popup-modal="user-created"]`);
                        popupModal.style.opacity = "1";
                        popupModal.style.pointerEvents = "auto";
                        bodyBlackout.style.display = "block";
                        popupModal.querySelector(".error").addEventListener("click", () => {
                           popupModal.style.removeProperty("opacity");
                           bodyBlackout.style.removeProperty("display");
                           window.location.reload();
                        });
                     }
                     // popUp('user-created')
                  )
                  .catch((error) => {
                     console.log(error);
                  });
            })
            .catch(async function (error) {
               // Handle Errors here.
               var errorCode = error.code;
               var errorMessage = error.message;
               console.log(errorMessage);
               if (errorCode == "auth/email-already-in-use") {
                  await wait();
                  popUp("email-already-exists");
               } else if (errorCode == "auth/internal-error") {
                  await wait();
                  popUp("internal-error");
               } else {
                  await wait();
                  popUp("internal-error");
               }
            });
      }
   });

   let login_email = document.getElementById("login-email");
   let login_password = document.getElementById("login-pass");
   login_email.addEventListener("input", handleInputLogin);
   login_password.addEventListener("input", handleInputLogin);

   function handleInputLogin() {
      if (login_email.value.match(emailRegex) && login_email.value != "" && login_password.value.match(/[A-Z]/) && login_password.value.match(/[a-z]/) && login_password.value.match(/[0-9]/) && login_password.value.match(/[^A-Za-z0-9]/) && login_password.value.length > 7) {
         loginBtn.classList.add("gradient");
      } else {
         try {
            loginBtn.classList.remove("gradient");
         } catch (error) {}
      }
   }

   loginBtn.addEventListener("click", async (e) => {
      wait(true);
      const email = document.getElementById("login-email").value;
      const password = document.getElementById("login-pass").value;
      if (!email.match(emailRegex) || email == "") {
         await wait();
         popUp("email");
      } else if (!password.match(/[A-Z]/) || !password.match(/[a-z]/) || !password.match(/[0-9]/) || !password.match(/[^A-Za-z0-9]/) || password.length < 8) {
         await wait();
         popUp("password");
      } else {
         const userCredential = await signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
               const user = userCredential.user;
               await wait();
               window.location.reload();
               // console.log(user)
            })
            .catch(async function (error) {
               // Handle Errors here.
               var errorCode = error.code;
               var errorMessage = error.message;
               console.log(errorMessage);
               if (errorCode == "auth/user-not-found") {
                  await wait();
                  popUp("user-not-found");
               } else if (errorCode == "auth/internal-error") {
                  await wait();
                  popUp("internal-error");
               }
            });
      }
   });

   let recover_email = document.getElementById("recover-acc-input");
   recover_email.addEventListener("input", handleInputRecover);

   function handleInputRecover() {
      if (recover_email.value.match(emailRegex) && recover_email.value != "") {
         recoverAccBtn.classList.add("gradient");
      } else {
         try {
            recoverAccBtn.classList.remove("gradient");
         } catch (error) {}
      }
   }

   recoverAccBtn.addEventListener("click", async (e) => {
      wait(true);
      const email = document.getElementById("recover-acc-input").value;
      if (!email.match(emailRegex) || email == "") {
         await wait();
         popUp("email");
      } else {
         sendPasswordResetEmail(auth, email)
            .then(async () => {
               await wait();
               popUp("recover-email-sent");
            })
            .catch(async (error) => {
               const errorCode = error.code;
               const errorMessage = error.message;
               console.log(error);
               if (errorCode == "auth/user-not-found") {
                  await wait();
                  popUp("user-not-found");
               } else if (errorCode == "auth/internal-error") {
                  await wait();
                  popUp("internal-error");
               }
               // ..
            });
      }
   });
};

export default loginPhase;
